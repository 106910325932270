<template>
  <span>
    <span>{{ diffTwoDates(date) }}</span>
    <span v-if="updatedDate"> (opd. {{ diffTwoDates(updatedDate) }})</span>
  </span>
</template>

<script setup lang="ts">
const props = defineProps({
  date: {
    type: Number,
    required: true
  },
  updatedDate: {
    type: Number,
  },
});
const diffTwoDates = (dateTimestamp: number) => {
  const dateNowTimestamp = new Date().valueOf();
  const date = new Date(dateTimestamp * 1000);
  const days = Math.floor((dateNowTimestamp - dateTimestamp * 1000) / 1000 / 60 / 60 / 24);
  const hours = Math.floor((dateNowTimestamp - dateTimestamp * 1000) / 1000 / 60 / 60);
  const minutes = Math.floor((dateNowTimestamp - dateTimestamp * 1000) / 1000 / 60);
  const seconds = Math.floor((dateNowTimestamp - dateTimestamp * 1000) / 1000);
  const monthsText = ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'];

  // Display seconds
  if (seconds < 60 && minutes === 0 && hours === 0 && days === 0) {
    return seconds !== 1 ? `${seconds} sek siden` : `${seconds} sek siden`;
  }

  // Display minutes
  if (minutes < 60 && hours === 0 && days === 0) {
    return minutes !== 1 ? `${minutes} min siden` : `${minutes} min siden`;
  }

  // Display hours
  if (hours < 24 && days === 0) {
    return hours !== 1 ? `${hours} timer siden` : `${hours} time siden`;
  }

  // Display "yesterday"
  if (days < 2) {
    return 'I går';
  }

  // Display date
  if (days >= 2) {
    return date.getDate() + '. ' + monthsText[date.getMonth()] + ' ' + date.getFullYear()
  }
};
</script>
